import React, { useState } from "react"

import Navigation from "../block/navigation"
import NavItem from "../item/navItem"
import Container from "../block/container"
import { useGlobalContext } from "../../context/context"

const HeaderSecondary = () => {
  const isBrowser = typeof window !== "undefined"
  const {
    modifiedLinksOrder,
    dynamicWindowSize,
    initialLinksOrder,
  } = useGlobalContext()
  const [navSecondaryOpen, setNavSecondaryOpen] = useState(false)
  const windowSize = isBrowser && window.innerWidth

  const handleToggle = () => {
    setNavSecondaryOpen(prev => !prev)
  }

  return (
    <div className="region region--header--secondary">
      <div className="region__inner">
        <Navigation
          title="Secondary navigation"
          classes={`menu--secondary${navSecondaryOpen ? " open" : ""}`}
        >
          {(dynamicWindowSize || windowSize) > 1199
            ? initialLinksOrder.map(item => <NavItem key={item.id} {...item} />)
            : modifiedLinksOrder.map(item => (
                <NavItem key={item.id} {...item} />
              ))}
        </Navigation>

        <Container
          classes="block--menu-secondary-trigger"
          navopen={navSecondaryOpen}
          onClick={() => setNavSecondaryOpen(!navSecondaryOpen)}
        >
          <button
            className={`button button--menu-secondary-trigger${
              navSecondaryOpen ? " open" : ""
            }`}
            onClick={handleToggle}
          >
            {navSecondaryOpen ? "Close" : "Open"} Navigation
          </button>
        </Container>
      </div>
    </div>
  )
}

export default HeaderSecondary
