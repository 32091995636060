import React, { useEffect } from "react"

import { useGlobalContext } from "../../context/context"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Header from "../../components/regions/header"
import HeaderSecondary from "../../components/regions/headerSecondary"
import Container from "../../components/block/container"
import FieldText from "../../components/field/fieldText"
import ImageTextList from "../../components/item/imageTextList"
import BlockClients from "../../components/full/blockClients"
import BlockTry from "../../components/full/blockTry"
import BlockTestimonials from "../../components/full/blockTestimonials"
import "../../styles/components/menu--secondary.scss"
import "../../styles/components/block--page-top-content--creative-agencies.scss"
import "../../styles/components/block--special-benefits.scss"
import "../../styles/components/block--menu-secondary-trigger.scss"

const CreativeAgencies = ({ location }) => {
  const { handleCurrentLocation } = useGlobalContext()

  useEffect(() => {
    handleCurrentLocation(location.pathname)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Header>
        <HeaderSecondary />
      </Header>
      <Layout>
        <Seo
          title="Creative Agencies"
          description="We Help Creative Teams Easily Manage Inbound Project Requests."
          pageClass="page--creative-agencies"
        />

        <Container classes="block--page-top-content block--page-top-content--creative-agencies">
          <FieldText>
            <h1>
              We Help <span className="text text--red">Creative Teams</span>{" "}
              Easily Manage Inbound Project Requests!
            </h1>
            <p>
              Sideline helps creative teams and agencies easily manage inbound
              project requests. Ready to boost your team's productivity?
            </p>
            <Link to={"#"} className="button button--big button--red">
              Schedule a Demo
            </Link>
            <Link to={"#"} className="button button--big button--transparent">
              Try It For Free for 14 Days!
            </Link>
            <p className="text text--smaller">
              14 days free trial&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;No
              contracts&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Cancel anytime
            </p>
          </FieldText>
          <StaticImage
            as="figure"
            className="field field--image"
            src="../../assets/images/schedule-demo.jpg"
            quality="90"
            placeholder="blurred"
            alt="Schedule a Demo"
            title="Schedule a Demo"
          />
        </Container>

        <BlockClients />

        <Container
          classes="block--special-benefits"
          title="Special Benefits for Creative Agencies"
        >
          <ImageTextList>
            <StaticImage
              as="figure"
              className="field field--image"
              src="../../assets/images/creative-agencies--set-your-due-date.jpg"
              quality="90"
              placeholder="blurred"
              alt="Set Your Due Date"
              title="Set Your Due Date"
            />
            <FieldText>
              <h3>Set Your Due Date</h3>
              <p>
                Clients submit their Requests through a custom intake form,
                which gathers up all of the necessary information.
              </p>
            </FieldText>
          </ImageTextList>

          <ImageTextList>
            <StaticImage
              as="figure"
              className="field field--image"
              src="../../assets/images/creative-agencies--create-tags.jpg"
              quality="90"
              placeholder="blurred"
              alt="Create Tags"
              title="Create Tags"
            />
            <FieldText>
              <h3>Create Tags</h3>
              <p>
                Requests are automatically assigned to the right person, and
                prioritized according to due date and urgency.
              </p>
            </FieldText>
          </ImageTextList>

          <ImageTextList>
            <StaticImage
              as="figure"
              className="field field--image"
              src="../../assets/images/creative-agencies--know-your-project-status.jpg"
              quality="90"
              placeholder="blurred"
              alt="Know Your Project Status"
              title="Know Your Project Status"
            />
            <FieldText>
              <h3>Know Your Project Status</h3>
              <p>
                Clients are always aware of who's working on their Requests and
                what's coming next. No need for those tedious status calls.
              </p>
            </FieldText>
          </ImageTextList>

          <ImageTextList>
            <StaticImage
              as="figure"
              className="field field--image"
              src="../../assets/images/creative-agencies--get-your-requests-in-order.jpg"
              quality="90"
              placeholder="blurred"
              alt="Get Your Requests in Order"
              title="Get Your Requests in Order"
            />
            <FieldText>
              <h3>Get Your Requests in Order</h3>
              <p>
                Clients are more likely to utilize your services when you make
                it easy for them to do so. Sideline makes it fun and easy.
              </p>
            </FieldText>
          </ImageTextList>

          <ImageTextList>
            <StaticImage
              as="figure"
              className="field field--image"
              src="../../assets/images/creative-agencies--add-internal-comments.jpg"
              quality="90"
              placeholder="blurred"
              alt="Add Internal Comments"
            />
            <FieldText>
              <h3>Add Internal Comments</h3>
              <p>
                Clients submit their Requests through a custom intake form,
                which gathers up all of the necessary information.
              </p>
            </FieldText>
          </ImageTextList>

          <ImageTextList>
            <StaticImage
              as="figure"
              className="field field--image"
              src="../../assets/images/creative-agencies--create-private-notes.jpg"
              quality="90"
              placeholder="blurred"
              alt="Create Private Notes"
            />
            <FieldText>
              <h3>Create Private Notes</h3>
              <p>
                Requests are automatically assigned to the right person, and
                prioritized according to due date and urgency.
              </p>
            </FieldText>
          </ImageTextList>

          <ImageTextList>
            <StaticImage
              as="figure"
              className="field field--image"
              src="../../assets/images/creative-agencies--requests.jpg"
              quality="90"
              placeholder="blurred"
              alt="Requests"
            />
            <FieldText>
              <h3>Requests</h3>
              <p>
                Clients are always aware of who's working on their Requests and
                what's coming next. No need for those tedious status calls.
              </p>
            </FieldText>
          </ImageTextList>

          <ImageTextList>
            <StaticImage
              as="figure"
              className="field field--image"
              src="../../assets/images/creative-agencies--ticket-details-in-one-place.jpg"
              quality="90"
              placeholder="blurred"
              alt="Ticket Details in One Place"
            />
            <FieldText>
              <h3>Ticket Details in One Place</h3>
              <p>
                Clients are more likely to utilize your services when you make
                it easy for them to do so. Sideline makes it fun and easy.
              </p>
            </FieldText>
          </ImageTextList>
        </Container>

        <BlockTestimonials />

        <BlockTry />
      </Layout>
    </>
  )
}

export default CreativeAgencies
